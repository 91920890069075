import classNames from 'classnames';
import { Fragment } from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import { formatPrice } from '../../../utils/product/formatPrice';
import { styles } from './Price.styles';

export interface PriceProps {
  price: number;
  currency: string;
  variant?: 'title' | 'body' | 'subhead' | 'caption' | 'smallCaption' | 'smallAccentedCaption' | 'mobile' | 'mobileSmall' | 'smallSubhead';
  isDiscount?: boolean;
  isMinor?: boolean;
  isUnavailable?: boolean;
  dataTestId?: string;
  dataMarker?: string;
  withDecimal?: boolean;
}

const Price = (props: PriceProps) => {
  const {
    price,
    currency,
    variant = 'body',
    isDiscount,
    isMinor,
    isUnavailable,
    dataTestId,
    dataMarker,
    withDecimal,
  } = props;

  const localize = useLocalization();

  return (
    <Fragment>
      <span
        className={classNames(`Price__value_${variant}`, {
          Price__value_discount: isDiscount,
          Price__value_minor: isMinor,
          Price__value_unavailable: isUnavailable,
        })}
        data-testid={dataTestId}
        data-marker={dataMarker}
      >
        {formatPrice(price, withDecimal)}
      </span>
      &nbsp;
      <span className={classNames(`Price__currency_${variant}`, {
        Price__currency_discount: isDiscount,
        Price__currency_minor: isMinor,
        Price__currency_unavailable: isUnavailable,
      })}>
        {`${localize(`general.currency.${currency}`)}`}
      </span>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default Price;
